<template>
  <b-card-code
    no-body
    title="Holiday List"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">

        <div class="col-md-6">
          <b-button
            v-if="checkPermission('create holiday')"
            v-b-modal.form-modal
            class="btn btn-info btn-sm mb-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Add Holiday"
            @click="cleanupForm()"
          >
            <plus-circle-icon
              size="1x"
              class="custom-class"
            /> Add Holiday
          </b-button>

          <b-modal
            id="form-modal"
            no-close-on-backdrop
            :title="editUuid != null ? 'Edit Holiday' : 'Add Holiday'"
          >
            <div class="form">

              <div class="form-group">
                <label for="">Title</label>
                <input
                  v-model="formPayload.title"
                  type="text"
                  class="form-control"
                  placeholder="Holiday title..."
                >
              </div>

              <div class="form-group">
                <label for="">Date</label><br>
                <flat-pickr
                  v-model="formPayload.date"
                  class="form-control"
                  placeholder="Holiday date..."
                  :config="{ enableTime: true,dateFormat: 'Y-m-d'}"
                />
              </div>

            </div>

            <template #modal-footer="{}">
              <b-button
                v-if="editUuid == null"
                variant="success"
                @click="createItem()"
              >
                Save Holiday
              </b-button>
              <b-button
                v-else
                variant="success"
                @click="updateItem()"
              >
                Save Holiday
              </b-button>
            </template>
          </b-modal>
        </div>

        <div class="col-md-6">
          <button
            v-if="checkPermission('sync holiday')"
            class="btn btn-success btn-sm float-right"
            @click="syncHoliday()"
          >
            <refresh-ccw-icon
              size="1x"
              class="custom-class"
            /> Sync with Google Calendar
          </button>
        </div>

      </div>
    </div>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Title</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="4"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in result.data"
            v-else
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.title }}</td>
            <td>
              <div
                class="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  v-if="checkPermission('update holiday')"
                  class="btn btn-info btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                  @click="editHoliday(item)"
                >
                  <edit-icon
                    size="1x"
                    class="custom-class"
                  />
                </button>
                <button
                  v-if="checkPermission('delete holiday')"
                  class="btn btn-danger btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delete"
                  @click="deleteItem(item.id)"
                >
                  <trash-2-icon
                    size="1x"
                    class="custom-class"
                  />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="4"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import {
  Trash2Icon, PlusCircleIcon, EditIcon, RefreshCcwIcon,
} from 'vue-feather-icons'
import flatPickr from 'vue-flatpickr-component'
import 'vue2-datepicker/index.css'

export default {
  title() {
    return `Holiday List`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    PlusCircleIcon,
    EditIcon,
    Trash2Icon,
    flatPickr,
    RefreshCcwIcon,
    BSpinner,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 150),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        keyword: '',
      },
      editUuid: null,
      formPayload: {
        title: '',
        date: '',
      },
      isLoading: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/holiday', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    createItem() {
      this.$http.post('/admin/holiday', this.formPayload)
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Holiday successfully created')

          this.cleanupForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/admin/holiday/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Holiday successfully deleted',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        }
      })
    },
    cleanupForm() {
      this.editUuid = null
      this.formPayload = {
        title: '',
        date: '',
      }
    },
    editHoliday(item) {
      this.editUuid = item.id
      this.formPayload = {
        title: item.title,
        date: item.date,
      }
      this.$bvModal.show('form-modal')
    },
    updateItem() {
      this.$http.patch(`/admin/holiday/${this.editUuid}`, this.formPayload)
        .then(response => {
          this.$bvModal.hide('form-modal')
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Holiday successfully updated!')

          this.cleanupForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
    syncHoliday() {
      this.$http.post('/admin/holiday/sync')
        .then(response => {
          this.getData(this.currentPage)
          successNotification(this, 'Success', 'Holiday successfully synced!')

          this.cleanupForm()
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
